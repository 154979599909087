import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: process.env.GATSBY_SITE_SENTRY_DSN,
  environment: process.env.GATSBY_SITE_SENTRY_ENVIRONMENT,
  release: process.env.GATSBY_SITE_SENTRY_RELEASE,
  enabled: process.env.GATSBY_SITE_SENTRY_ENVIRONMENT !== 'local',
  tunnel: process.env.GATSBY_SITE_SENTRY_TUNNEL,
  ignoreErrors: [
    /TypeError: Load failed/g,
    /beacon.min.js/g,
    /ChunkLoadError/g,
    /Permission Denied/g,
  ],
  beforeSend: (event) => {
    const exception = event.exception?.values && event.exception?.values[0]
    const extraData = event.extra && event.extra['__serialized__']
    const isNotUserAction = extraData?.isTrusted === false

    if (
      exception?.value ===
        'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target' &&
      isNotUserAction
    ) {
      return null
    }

    return event
  },
})
